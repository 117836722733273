<template>
  <div class="">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn-info">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'sections-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer une nouvelle catégorie
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence Nº</th>
          <th scope="col">Désignation</th>
          <th scope="col">Fournisseur</th>
          <th scope="col">Dépôt</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(section, index) in sections" :key="index++">
          <td>
            {{ index }}
          </td>
          <td class="p-0 m-0">
            {{ section.reference }}
          </td>
          <td>
            {{ section.fullName }}
          </td>
          <td>
            <span v-if="section.supplier">
              {{ section.supplier.society }}
            </span>
          </td>

          <td>
            <span v-if="section.deposit">
              {{ section.deposit.fullName }}
            </span>
          </td>


          <td class="d-flex">
            <button
              @click="
                $router.push({
                  name: 'sections-edit',
                  params: {
                    reference: section.reference,
                  },
                })
              "
              class="btn"
            >
              <i class="bi bi-pencil-square"></i>
            </button>
            <button @click="remove(section)" class="btn">
              <i class="bi bi-trash text-danger"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("section", {
      sections: "getSections",
    }),
  },
  mounted() {
    this.$store.dispatch("section/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("section/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("section/getAll");
    },

    async remove(data) {
      let section = data;
      await this.$confirm({
        message: "Vous voulez supprimer la categorie  " + section.fullName,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("section/destroy", section.reference);
          }
        },
      });
    },
    async sort(value) {
      return await this.$store.commit("section/sort", value);
    },
  },
};
</script>
