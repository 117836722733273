var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-info",on:{"click":function($event){return _vm.refresh()}}},[_c('i',{staticClass:"bi bi-arrow-clockwise"}),_vm._v(" Actualiser ")])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push({ name: 'sections-create' })}}},[_c('i',{staticClass:"bi bi-plus-square"}),_vm._v(" Créer une nouvelle catégorie ")])])]),_c('br'),_c('table',{staticClass:"table table-striped table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.sections),function(section,index){return _c('tr',{key:index++},[_c('td',[_vm._v(" "+_vm._s(index)+" ")]),_c('td',{staticClass:"p-0 m-0"},[_vm._v(" "+_vm._s(section.reference)+" ")]),_c('td',[_vm._v(" "+_vm._s(section.fullName)+" ")]),_c('td',[(section.supplier)?_c('span',[_vm._v(" "+_vm._s(section.supplier.society)+" ")]):_vm._e()]),_c('td',[(section.deposit)?_c('span',[_vm._v(" "+_vm._s(section.deposit.fullName)+" ")]):_vm._e()]),_c('td',{staticClass:"d-flex"},[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.$router.push({
                name: 'sections-edit',
                params: {
                  reference: section.reference,
                },
              })}}},[_c('i',{staticClass:"bi bi-pencil-square"})]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.remove(section)}}},[_c('i',{staticClass:"bi bi-trash text-danger"})])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}}),_c('th',{attrs:{"scope":"col"}},[_vm._v("Référence Nº")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Désignation")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Fournisseur")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Dépôt")]),_c('th',{attrs:{"scope":"col"}})])])
}]

export { render, staticRenderFns }